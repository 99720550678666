import { 
    FETCH_INFERENCE_SUCCESS, 
    FETCH_INFERENCE_REQUEST,
    FETCH_INFERENCE_FAILURE 
} from '../types';

const initialState = {
    error: null,
    isFetching: false,
    modelType: null,
    variantList: [],
    featureNames: [],
    shapleyValues: [],
    featureValues: [],
    probabilities: [],
    normalizedFeatureValues: [],
    contributionPctObjectList: [],
}

function MissenseVariantInferenceReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_INFERENCE_REQUEST:
            return {
                ...state,
                isFetching: true
            }

        case FETCH_INFERENCE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                modelType: action.modelType,
                variantList: action.variantList,
                featureNames: action.inference.featureNames,
                shapleyValues: action.inference.shapleyValues,
                probabilities: action.inference.probabilities,
                featureValues: action.inference.featureValues,
                normalizedFeatureValues: action.inference.normalizedFeatureValues,
                contributionPctObjectList: action.inference.contributionPctObjectList,
            }

        case FETCH_INFERENCE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error
            }
        
        default: 
            return state;
    }
}

export default MissenseVariantInferenceReducer;