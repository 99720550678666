import React from 'react';

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from '../../modules/custom-plotly'
import { featureDescription, featureCategories } from '../../fixtures'

import './index.css'

/**
 * Similar to python zip except returns a list of lists rather than tuples.
 * @param {array[array]} arrays 
 */
function zip(arrays) {
    return arrays[0].map(function(_, i){
        return arrays.map(function(array){return array[i]})
    });
}

/** Return column n of matrix. */
const arrayColumn = (matrix, n) => matrix.map(x => x[n]);

/**
 * Given a name and category, return the a formatted HTML string for the plot.
 * @param {string} name 
 * @param {string} category 
 */
function categoryBasedHtml(name, category){
    const categoryLetter = featureCategories[category]["letter"]
    const categoryColor = featureCategories[category]["colorClass"]
    let s = '<span>'
    s += `<span style="float: left; color: ${categoryColor}">${categoryLetter}</span>`
    s += " - "
    s += `<span style="display: block">${name}</span>`
    s += "</span>"
    return s
}

/**
 * Given the required data, create a horizontal bar chart
 * @param {array[string]} featureNames
 * @param {array[float]} shapleyValues
 */
const ShapleyHorizontalBarChart = ({ featureNames, shapleyValues, normalizedFeatureValues, toggleShortFeatNames, cutoff_value }) => {

    // convert featureNames to featureDescriptions
    featureNames = featureNames.map(feat=> {
        const name = toggleShortFeatNames ? featureDescription[feat]['name'] : feat
        return categoryBasedHtml(name, featureDescription[feat]['category'])
    })

    // sort shapley values by their absolute values least to greatest because Plotly reverses them.
    let values = zip([
        featureNames, shapleyValues, normalizedFeatureValues, shapleyValues.map(Math.abs)])
    values.sort((first, second) => first[3] - second[3]);

    // visualize top "cutoff_value" features
    values = values.slice(values.length - cutoff_value, values.length);

    featureNames = arrayColumn(values, 0)
    const sortedShaps = arrayColumn(values, 1)
    normalizedFeatureValues = arrayColumn(values, 2)

    const Plot = createPlotlyComponent(Plotly);
    const currentDate = new Date()
    let title = "Top " + cutoff_value + " Contributing Feature"
    if (cutoff_value > 1) title += "s"

    return <Plot 
        className="ShapleyHorizontalBarChart"
        layout={{
            autosize: true,
            title: title,
            width: 950,
            xaxis: {
                automargin: true,
                title: "Benign Factor <----> Pathogenic Factor"
            },
            yaxis: {
                automargin: true
            },
            annotations: [{
                xref: "paper",
                yref: "paper",
                x: 0.0, // higher value more right 
                y: -0.3,
                text: (
                    "SNPDogg Model Version 1.0 (Accessed Data: " + 
                    (currentDate.getMonth()+1) + '/' +
                    currentDate.getDate() + '/' +
                    currentDate.getFullYear() + ")"),
                showarrow: false,
                font: {
                    family: "Arial",
                    size: 10,
                    color: "rgb(150,150,150)",
                }
            }]
        }}
        config={{"displaylogo": false, "responsive": true}}
        data={[{
            x: sortedShaps,
            y: featureNames,
            type: 'bar',
            orientation: "h",
            showlegend: false,
            hovertext: featureNames,
            hoverinfo: "text",
            marker: {
                color: normalizedFeatureValues,
                colorscale: [
                    ['0.0', ('rgb(175,141,195)')],
                    ['0.5', ('rgb(247,247,247)')],
                    ['1.0', ('rgb(127,191,123)')]
                ],
                colorbar: {
                    title: {
                        text: "Normalized Feature Value",
                        side: "top"
                    }
                },
                cmin: -2,
                cmax: 2,
                line: {
                    width: 1
                }
            }}]}/>
}

export default ShapleyHorizontalBarChart