import axios from 'axios'
import { connect } from "react-redux"
import React, { Component, useState } from 'react'
import { addCurrentlySelectedVariantsAction } from "../../redux/actions"
import { Dropdown } from 'semantic-ui-react'

import config from '../../config';

// regex expressions
export const regex = RegExp(/^(((( +)?(,?|$)( +)?([XxYy]|\d{1,22}|$)(-|$)(\d+|$)(-|$)([AaGgCcTt]|$)(-|$)([AaGgCcTt]|$)))+)$/)

const apiUrl = config.snpdoggApi.URL;

/** Convert valueArray for Dropdown
 * valueArray: array[string] 
 * returns array[object] */
const toDropdownItems = (valueArray) => {
    return valueArray.map(value => {return {"key": value, "value": value, "text": value}});
}

const caseSensitiveSearch = (options, query) => {
    // if (query.split(",").length !== options.length){
    //     return options.filter(opt => opt)
    // }
    return options
}

const copyCodeToClipboard = (e, label) => {
    navigator.clipboard.writeText(label.value).then(function() {
        alert('Copy successful!');
      }, function(err) {
        alert('Could not copy text: ', err);
      });
}

/** Fetch variants from API. Remove duplicates before creating url.
 * typedText: string
 * returns array[string]
*/
async function fetchVariants(typedText) {
    if (typedText) {
        typedText = [...new Set(typedText.split(","))].join(",")
        const response = await axios.get(
            apiUrl + "/variant-autocomplete/" + typedText, {
            headers: {
                "Content-Type": 'application/json'
            }});
        const variants = await response.data['variant-text'];
        return variants
    }
    return []
}

function SearchBar({ addCurrentlySelectedVariantsAction }) {

    const [ options, setOptions ] = useState([])
    const [ selectedVariants, setSelectedVariants ] = useState([])
    const [ error, setError ] = useState(false)
    const [ open, setOpen ] = useState(false)

    /** when a user enters text in the search box 
     * searchQuery: string */
    async function onSearchChange(e, { searchQuery }) {
        searchQuery = searchQuery.replace(/ /g, "")
        const isValidSearch = regex.test(searchQuery)
        if (isValidSearch){
            setError(false)
            // multi-variant query
            if (searchQuery.includes(",")){
                let variants = await fetchVariants(searchQuery)
                setOpen(false)
                setOptions([])
                setSelectedVariants([])
                addCurrentlySelectedVariantsAction(variants)
            } 
            // empty searchQuery
            else if (searchQuery.length === 0) {
                setOpen(false)
                setOptions([])
            }
            // single-variant query
            else {
                let variants = await fetchVariants(searchQuery)
                variants = toDropdownItems(variants)
                setOpen(true)
                setOptions(variants.concat(options))
            }
        } else {
            setError(true)
        }
    }

    /** when a user adds or deletes a selection
     * (value: array[string]) 
     * */
    function onChange(_, { value }) {
        setSelectedVariants(value)
        addCurrentlySelectedVariantsAction(value)
        setOptions(toDropdownItems(value))
        setOpen(false)
    }

    return <Dropdown
        fluid
        open={open}
        icon={null}
        search={caseSensitiveSearch}
        multiple
        selection
        error={error}
        value={selectedVariants}
        noResultsMessage={"Type a variant, or paste multiple variants."}
        className="search-bar"
        placeholder='chr-pos-ref-alt'
        options={options}
        onLabelClick={copyCodeToClipboard}
        onChange={onChange}
        onSearchChange={onSearchChange}
    />
}

export const ReduxSearchBar = connect(null, { addCurrentlySelectedVariantsAction })(SearchBar);