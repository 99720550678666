import React from 'react'

import { connect } from 'react-redux'
import Loader from 'react-loader-spinner'
import { Menu, Segment, Grid, Statistic, Transition } from 'semantic-ui-react'

import { getMissenseInferenceState, getPlotSelectState } from '../../redux/selectors'

import PlotToggleButtons from '../PlotToggleButtons'
import ShapleyHorizontalBarChart from '../topContributionPlot'
import { featureCategories } from '../../fixtures'

import './index.css'

class PlotList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: null,
      cutoff_value: 10
    }
  }

  handleRangeChange = e => this.setState({ cutoff_value: e.target.value })

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const {
      // inference data
      modelType,
      isFetching,
      variantList,
      featureNames,
      probabilities,
      shapleyValues,
      normalizedFeatureValues
    } = this.props

    // if it is currently fetching the inference data, return a loader
    if (isFetching === true) {
      return (
        <div className='PlotList'>
          <Loader
            type='Rings'
            color='#2196F3'
            height={300}
            width={300}
            visible={isFetching}
          />
        </div>
      )
    }

    // if there are no variants, return an empty div
    if (variantList.length === 0) return <div className='PlotList' />

    // create plots for each variant
    let plotGroupObject = {}

    const { cutoff_value } = this.state

    for (let i = 0; i < variantList.length; i++) {
      const variant = variantList[i]
      const roundedProb = (probabilities[i] * 100).toPrecision(3)
      const pathogenic = roundedProb >= 50.0
      const classfication = pathogenic ? 'Pathogenic' : 'Benign'
      const colorClass = pathogenic ? 'red' : 'blue'
      const modelName = modelType === 'meta' ? 'Meta' : 'Raw'

      const plotGroup = (
        <Grid>
          <Grid.Row centered>
            <h3 className={colorClass}>{variant}</h3>
          </Grid.Row>
          <Grid.Row centered>
            <Statistic.Group centered="true" size='tiny'>
              <Statistic
                color={colorClass}
                value={roundedProb + '%'}
                label='Pathogenic'
              />
              <Statistic
                color={colorClass}
                value={classfication}
                label='Classification'
              />
              <Statistic
                size={'small'}
                color={colorClass}
                value={modelName}
                label='Model'
              />
            </Statistic.Group>
          </Grid.Row>
          <Grid.Row centered>
            <label>
              <div>
                <h4>Slide to change number of features</h4>
              </div>
              <input
                type='range'
                min={1}
                max={50}
                value={cutoff_value}
                onChange={this.handleRangeChange}
              />
            </label>
          </Grid.Row>
          <Transition
            visible={this.props.toggleTopContributionPlot}
            animation='scale'
            unmountOnHide={true}
            duration={500}
          >
            <Grid.Row centered={true}>
              <ShapleyHorizontalBarChart
                cutoff_value={cutoff_value}
                shapleyValues={shapleyValues[i]}
                featureNames={featureNames}
                toggleShortFeatNames={this.props.toggleShortFeatNames}
                normalizedFeatureValues={normalizedFeatureValues[i]}
              />
            </Grid.Row>
          </Transition>
        </Grid>
      )

      plotGroupObject[variant] = plotGroup
    }

    const activeItem = this.state.activeItem
      ? this.state.activeItem
      : variantList[0]

    const menuItems = variantList.map((v, i) => (
      <Menu.Item
        key={'menuItem' + v + i}
        name={v}
        active={activeItem === v}
        onClick={this.handleItemClick}
      />
    ))

    return (
      <Grid columns='equal' className='menu-right' textAlign='center'>
        <Grid.Row centered>
          <h1>Feature Contribution Plots</h1>
        </Grid.Row>
        <Grid.Row centered>
          <PlotToggleButtons />
        </Grid.Row>
        <Grid.Row centered>
          <div className='menu-plot-group'>
            <div className='variant-menu'>
              <Menu vertical fluid>
                <div className='variant-menu-list'>{menuItems}</div>
              </Menu>
            </div>
            <div>
              <Segment>
                {plotGroupObject[activeItem] || 'Select a variant to the left'}
              </Segment>
            </div>
          </div>
        </Grid.Row>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  ...getPlotSelectState(state),
  ...getMissenseInferenceState(state)
})

export default connect(mapStateToProps)(PlotList)
