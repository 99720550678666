const dev = {
    snpdoggApi: {
      NAME: "snpdogg-client",
      URL: "https://tvbe9zkgmc.execute-api.us-east-2.amazonaws.com/prod/"
    },
};

const prod = {
    snpdoggApi: {
      NAME: "snpdogg-client",
      URL: "https://d7956ca9t2.execute-api.us-east-2.amazonaws.com/prod"
    },
};

const config = process.env.REACT_APP_ENV === 'prod'
  ? prod
  : dev;


export default {
    ...config
  };