import { ADD_CURRENTLY_SELECTED_VARIANTS } from "../types"

const initialState = {
    variantList: [],
};

const VariantSelectReducer = (state=initialState, action) => {
    switch (action.type) {
        case ADD_CURRENTLY_SELECTED_VARIANTS: {
            return {
                ...state,
                variantList: action.payload.variants
            }
        }
        default: { return state }
    }
}

export default VariantSelectReducer;