/*
Reducer functions for actions that take place within the Model type dropdown field component.
*/

import { CHANGE_MODEL_TYPE } from "../types"

const initialState = {
    modelType: 'raw',
};

const ModelTypeSelectReducer = (state=initialState, action) => {
    switch (action.type) {
        case CHANGE_MODEL_TYPE: {
            return {
                ...state,
                modelType: action.payload.modelType
            }
        }
        default: { return state }
    }
}

export default ModelTypeSelectReducer;