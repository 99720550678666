import React from 'react'

import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'

import { changeModelTypeAction } from '../../redux/actions'
import { getModelTypeSelectState } from '../../redux/selectors'

const raw = "raw"
const meta = "meta"

class ModelSelect extends React.Component {

    constructor(props){
        super(props);
        const { changeModelType } = props;
        this.state = {
            changeModelType: changeModelType,
            modelTypeList: [
                {"key": raw, "value": raw, "text": raw}, 
                {"key": meta, "value": meta, "text": meta}
            ]
        }
    }

    onChange = (_, modelType) => 
        this.setState({modelType: modelType.value}, () => 
            this.props.changeModelTypeAction(modelType.value));

    render(){
        return (
            <Form.Select
                options={ this.state.modelTypeList } 
                defaultValue={ this.state.modelTypeList[0]['value'] }
                onChange={ this.onChange }
        />)
    }
};

const mapStateToProps = state => ({
    ...getModelTypeSelectState(state)
});

const mapDispatchToProps = { changeModelTypeAction };

export default connect(mapStateToProps, mapDispatchToProps)(ModelSelect);


