import React from 'react';
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import './index.css'
import './nch_horizontal_logo.png'

function IgmImageLink({ imgPath }) {
  const igmUrl = "https://www.nationwidechildrens.org/"
  const description = "Nationwide Children's Hospital"
  return <a href={igmUrl} target="_blank" rel="noopener noreferrer"><img src={imgPath} alt={description}/></a>
}

export default function NavBar({ inverted }) {

  let nchLogo;
  if (inverted) {
    nchLogo = <IgmImageLink imgPath={require("./nch_horizontal_white_text_logo.png")}/>
  } else {
    nchLogo = <IgmImageLink imgPath={require("./nch_horizontal_logo.png")}/>
  }

  return (
    <Menu fixed="top" inverted={inverted}>
      <Menu.Item>
        <span className="snpdogg-brand" id="id-navbar-brand">SNPDOGG</span>
      </Menu.Item>
      <Menu.Item 
        onClick={() => window.location.reload(false)}
        link={false}
      ><Link to="/">Home</Link></Menu.Item>
      <Menu.Item link={false}><Link to="/about">About</Link></Menu.Item>
      {/* <Menu.Item position="right"><div id="id-navbar-nch-logo">{nchLogo}</div></Menu.Item> */}
    </Menu>
  )
}
