import React from 'react';
import PropTypes from 'prop-types'

import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react'

import { fetchInference } from '../../redux/asyncActions'
import { getVariantSelectState, getModelTypeSelectState } from '../../redux/selectors'

class FetchMissenseInference extends React.Component {

    onSubmit = (e) => {
        e.preventDefault();
        const { dispatch, modelType, variantList } = this.props
        if (variantList.length > 0) {
            dispatch(fetchInference(modelType, variantList))
        } else {
            alert("Please select a variant using the search engine below.")
        }
    }

    render = () => <Button 
        primary 
        animated 
        circular
        type="submit" 
        onClick={this.onSubmit}>
            <Button.Content visible>Enter</Button.Content>
            <Button.Content hidden>Predict</Button.Content>
        </Button>;
}

FetchMissenseInference.propTypes = {
    dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    variantList: getVariantSelectState(state).variantList,
    modelType: getModelTypeSelectState(state).modelType
})

export default connect(mapStateToProps)(FetchMissenseInference);
