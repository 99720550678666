import {
    TOGGLE_TOP_CONTRIBUTION_PLOT,
    TOGGLE_CONTRIBUTION_PCT_PLOT,
    TOGGLE_SHORT_PLOT_FEAT_NAMES,
    ADD_CURRENTLY_SELECTED_VARIANTS,
    CHANGE_MODEL_TYPE,
    ACTIVE_VARIANT_ITEM,
    FETCH_INFERENCE_REQUEST,
    FETCH_INFERENCE_SUCCESS,
    FETCH_INFERENCE_FAILURE
} from './types';

export const toggleTopContributionPlotAction = active => ({ 
    type: TOGGLE_TOP_CONTRIBUTION_PLOT, 
    payload: { active }
});

export const toggleContributionPctPlotAction = active => ({ 
    type: TOGGLE_CONTRIBUTION_PCT_PLOT, 
    payload: { active }
});

export const toggleShortPlotFeatureNamesAction = active => ({ 
    type: TOGGLE_SHORT_PLOT_FEAT_NAMES, 
    payload: { active }
});

export const addCurrentlySelectedVariantsAction = variants => ({ 
    type: ADD_CURRENTLY_SELECTED_VARIANTS, 
    payload: { variants }
});

export const changeModelTypeAction = modelType => ({ 
    type: CHANGE_MODEL_TYPE, 
    payload: { modelType }
});

export const activeVariantItemAction = variant => ({
    type: ACTIVE_VARIANT_ITEM,
    activeVariant: { variant }
})

// missense variant inference request actions

export const requestInferenceAction = (modelType, variantList) => ({ 
    type: FETCH_INFERENCE_REQUEST, 
    modelType: modelType,
    variantList: variantList
});

export const receiveInferenceAction = (modelType, variantList, inference) => ({
    type: FETCH_INFERENCE_SUCCESS,
    modelType: modelType,
    variantList: variantList,
    inference
})

export const requestInferenceFailureAction= error => ({
    type: FETCH_INFERENCE_FAILURE,
    payload: {
      error
    }
})
