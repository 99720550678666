import React from 'react'
import {BrowserRouter as Router, Switch, Route } from "react-router-dom";

import store from './redux/store'
import { Provider } from 'react-redux'

import './App.css';
import NavBar from './components/NavBar'
import Home from './components/Home'
import About from './components/About'

class App extends React.Component {

   state = { isTop: false }

   /* If window is scrolled all the way to the top, set isTop to true.*/
   setIsTop(){
      document.addEventListener('scroll', () => {
         const isTop = window.scrollY < 100;
         if (isTop !== this.state.isTop) {
            this.setState({ isTop })
         }
      });
   }

   render() {

      const { isTop } = this.state

      return (
         <Provider store={store}>         
            <Router>
               <div className="App">
                  <NavBar inverted={!isTop} />
                  <Switch>
                     <Route path="/about">
                        <div className="App-content"><About /></div>
                     </Route>
                     <Route path="/" exact>
                        <div className="App-content"><Home /></div>
                     </Route>
                  </Switch>
               </div>
            </Router>
         </Provider>
      )
   }
}

//export default withAuthenticator(App, true)
export default App;
