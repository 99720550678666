import React from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from "react-redux"

import { 
    toggleContributionPctPlotAction, 
    toggleTopContributionPlotAction, 
    toggleShortPlotFeatureNamesAction 
} from "../../redux/actions"
import { getPlotSelectState, getModelTypeSelectState } from '../../redux/selectors'

import './index.css'

const PlotToggleButtons = ({
    toggleShortFeatNames, 
    toggleTopContributionPlot, 
    toggleTopContributionPlotAction, 
    toggleShortPlotFeatureNamesAction }) => {      

    return <div className="metaDataBtns">
        <Button
            content={"Top Important Features"}
            active={toggleTopContributionPlot}
            key={"togglebtn-topcontributionplot"}
            className={toggleTopContributionPlot ? "active-btn" : "inactive-btn"}
            onClick={() => toggleTopContributionPlotAction(!toggleTopContributionPlot)}
        />
        <Button
            active={toggleShortFeatNames}
            content={"Short-hand Feature Descriptions"}
            className={toggleShortFeatNames ? "active-btn" : "inactive-btn"}
            onClick={() => toggleShortPlotFeatureNamesAction(!toggleShortFeatNames)}
        />
    </div>
}

const mapStateToProps = state => ({
    ...getPlotSelectState(state), 
    ...getModelTypeSelectState(state)
});

export default connect(
    mapStateToProps , { 
        toggleTopContributionPlotAction, 
        toggleContributionPctPlotAction, 
        toggleShortPlotFeatureNamesAction
})(PlotToggleButtons);