
// toggling plot displays
export const TOGGLE_TOP_CONTRIBUTION_PLOT = "TOGGLE_TOP_CONTRIBUTION_PLOT";
export const TOGGLE_CONTRIBUTION_PCT_PLOT = "TOGGLE_CONTRIBUTION_PCT_PLOT";
export const TOGGLE_SHORT_PLOT_FEAT_NAMES = "TOGGLE_SHORT_PLOT_FEAT_NAMES";

export const ADD_CURRENTLY_SELECTED_VARIANTS = "ADD_CURRENTLY_SELECTED_VARIANTS";

export const CHANGE_MODEL_TYPE = "CHANGE_MODEL_TYPE";

export const ACTIVE_VARIANT_ITEM = "ACTIVE_VARIANT_ITEM";

// async actions
export const FETCH_INFERENCE_REQUEST = "FETCH_INFERENCE_REQUEST";
export const FETCH_INFERENCE_SUCCESS = "FETCH_INFERENCE_SUCCESS";
export const FETCH_INFERENCE_FAILURE = "FETCH_INFERENCE_FAILURE";
