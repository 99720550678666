import React from 'react';

import { Grid } from "semantic-ui-react"

import PlotList from '../PlotList'
import { ReduxSearchBar } from '../SearchBar'
import ModelSelect from '../ModelSelect'
import FetchMissenseInference from '../FetchMissenseInference'

import './index.css'

const Home = () => {
   return (
      <div className="home">
         <Grid className="Content">
            <Grid.Row centered><h1>SNPDogg</h1></Grid.Row>
            <Grid.Row centered><h3 className="subtitle">Single Nucleotide Polymorphism Deleteriousness Observation in <br/> Genomes using Gradient boosted trees</h3></Grid.Row>
            <Grid.Row centered>
               <p>
                  <b>SNPDogg</b> is powered by the <a href="https://www.nationwidechildrens.org/specialties/institute-for-genomic-medicine" target="_blank" rel="noopener noreferrer"> Institute for Genomic Medicine</a>
                  <br/>
                  Enter the coordinates (hg19 build) of a missense variant below: chr-pos-ref-alt
               </p>
            </Grid.Row>
            <Grid.Row centered>
               <div>
                  <div className="group">
                     <div className="search-bar"><ReduxSearchBar /></div>
                     <div><FetchMissenseInference/></div>
                  </div>
                  <ModelSelect></ModelSelect>
               </div>
            </Grid.Row>
            <Grid.Row centered><PlotList className="Content-PlotList" /></Grid.Row>
         </Grid>
      </div>
   );
}

export default Home;