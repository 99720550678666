import { combineReducers } from "redux";
import PlotSelectReducer from "./plotSelect"
import VariantSelectReducer from "./variantSelect"
import ModelTypeSelectReducer from "./modelTypeSelect"
import MissenseVariantInferenceReducer from "./missenseVariantInference"

export default combineReducers({
    PlotSelectReducer,
    VariantSelectReducer,
    ModelTypeSelectReducer, 
    MissenseVariantInferenceReducer
});