import axios from 'axios'

import config from '../config';
import { 
    receiveInferenceAction,
    requestInferenceAction,
    requestInferenceFailureAction
} from './actions'

const apiUrl = config.snpdoggApi.URL;

// async action for fetching inference and then executing plots
export const fetchInference = (modelType, variantList) => {
    return async dispatch => {
        dispatch(requestInferenceAction(modelType, variantList));
        const variantStr = variantList.join(",");
        const url = apiUrl + "/missense-variant-inference/" + modelType + "/" + variantStr;
        axios
            .get(url)
            .then(res => {
                const body = res.data;
                dispatch(receiveInferenceAction(modelType, variantList, {
                    shapleyValues: body["shapley_values"],
                    featureNames: body["feature_names"],
                    featureValues: body["feature_values"],
                    normalizedFeatureValues: body["feature_zscore"],
                    contributionPctObjectList: body["contribution_pct_records"],
                    probabilities: body['probabilities']
                }));
            })
            .catch(err => {
                dispatch(requestInferenceFailureAction(err.message))
            })
    }
}