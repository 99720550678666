import rootReducer from './reducers'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

const middlewares = [thunk, createLogger()]

export default createStore(
    rootReducer, 
    applyMiddleware(...middlewares)
);